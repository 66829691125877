import {
  REQUEST,
  RECEIVE,
  FAILURE
} from '../../constant-type/constant-type-payment';

const initialState = {
  loading: false,
  code: null,
  payload: {},
  message: ''
};

const paymentPost = (
  state = initialState,
  { type, code, payload, message }
) => {
  switch (type) {
    case REQUEST.POST_PAYMENT:
      return { ...state, loading: true };

    case RECEIVE.POST_PAYMENT:
      return {
        ...state,
        loading: false,
        code,
        payload,
        message
      };

    case FAILURE.POST_PAYMENT:
      return {
        ...state,
        loading: false,
        code,
        payload,
        message
      };
    case 'SET_DEFAULT':
      return {
        ...state,
        loading: false,
        code,
        payload,
        message
      };
    default:
      return state;
  }
};

export default paymentPost;
