export const REQUEST = {
  USER: 'REQUEST.USER',
};
export const RECEIVE = {
  USER: 'RECEIVE.USER',
};

export const DELETE = {
  USER_MANAGEMENT: 'DELETE_USER_MANAGEMENT',
};

export const PUT = {
  PROFILE: 'PUT_PROFILE',
  PASSWORD: 'PUT_PASSWORD',
};
