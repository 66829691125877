import { REQUEST, RECEIVE } from '../../constant-type/constant-type-req-rec';

const InitialState = {
  loading: false,
  code: null,
  payload: {},
};

const user = (state = InitialState, { type, payload, code }) => {
  switch (type) {
    case REQUEST.PROFILE:
      return Object.assign({}, state, { loading: true });
    case RECEIVE.PROFILE:
      return Object.assign({}, state, { loading: false, payload, code });

    default:
      return state;
  }
};

export default user;
