import React, { Fragment, useEffect } from 'react';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import Button from 'reactstrap/lib/Button';
import { useDispatch, useSelector } from 'react-redux';

export default function ModalInformationSystem(props) {
  const store_log_system = useSelector((state) => state.log_system);
  const dispatch = useDispatch();

  const handleConfirm = () => {
    dispatch({ type: 'CLEAR_BAD_REQUEST' });
    window.location.reload();
  };

  useEffect(() => {
    return () => {
      dispatch({ type: 'CLEAR_BAD_REQUEST' });
    };
  }, [dispatch]);

  return (
    <Fragment>
      <Modal isOpen={store_log_system.status} centered size="sm" fade={false}>
        <ModalBody>
          <div className="mb-1 text-center">
            <i className="fas fa-exclamation-triangle mr-1" />
            <h6>Informasi !</h6>
          </div>
          <div className="text-center">
            Maaf terjadi kesalahan pada Permintaan Anda, silakan coba beberapa saat
            lagi!
          </div>
          <div className="mt-3 d-flex justify-content-center button-group">
            <Button size="sm" color="success" onClick={handleConfirm}>
              Coba Lagi
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </Fragment>
  );
}
