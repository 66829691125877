import {
  REQUEST,
  RECEIVE,
  FAILURE
} from '../../constant-type/constant-type-socket-payment';

const initialState = {
  payload: []
};

const notifPayment = (
  state = initialState,
  { type, payload, code, message }
) => {
  switch (type) {
    case REQUEST.GET_NOTIFICATION_PAYMENT:
      return { ...state, loading: true };

    case RECEIVE.GET_NOTIFICATION_PAYMENT:
      return {
        ...state,
        loading: false,
        code,
        payload,
        message
      };

    case FAILURE.GET_NOTIFICATION_PAYMENT:
      return {
        ...state,
        loading: false,
        code,
        payload,
        message
      };

    case REQUEST.SOCKET_ON_LIST_PAYMENT:
      return { ...state, loading: true };

    case RECEIVE.SOCKET_ON_LIST_PAYMENT:
      return {
        ...state,
        loading: false,
        code,
        payload,
        message
      };

    case FAILURE.SOCKET_ON_LIST_PAYMENT:
      return {
        ...state,
        loading: false,
        code,
        payload,
        message
      };

    case REQUEST.SOCKET_ON_PAYMENT:
      return { ...state, loading: true };

    case RECEIVE.SOCKET_ON_PAYMENT:
      return {
        ...state,
        loading: false,
        code,
        payload,
        message
      };

    case FAILURE.SOCKET_ON_PAYMENT:
      return {
        ...state,
        loading: false,
        code,
        payload,
        message
      };

    default:
      return state;
  }
};

export default notifPayment;
