export const CONS_UNREAD_NOTIF = 'UNREAD_NOTIF';
export const CONS_SET_ID_NOTIF = 'PUSH_SET_ID_NOTIF';
export const CONS_LIST_NOTIF = 'LIST_NOTIF';

export const NOTIF_ACT = {};
NOTIF_ACT.push = ({ type, data, dispatch }) => {
  dispatch({ type, payload: data });
};

NOTIF_ACT.unread = ({ data, dispatch }) => {
  dispatch({
    type: CONS_UNREAD_NOTIF,
    payload: data,
  });
};

NOTIF_ACT.list = ({ data, dispatch }) => {
  dispatch({
    type: CONS_LIST_NOTIF,
    payload: data,
  });
};
