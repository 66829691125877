import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import loggerMiddlware from 'redux-logger';
import reducers from './reducers';

import monitorReducersEnhancer from './MonitorReducers';

export default function configureStore(preloadedState) {
  const middlewares = [thunkMiddleware];
  if (process.env.NODE_ENV === 'development') {
    middlewares.push(loggerMiddlware);
  }

  if (process.env.NODE_ENV === 'production') {
    console.log = function() {};
    console.info = function() {};
    console.warn = function() {};
    console.error = function() {};
  }
  const middlewareEnhancer = applyMiddleware(...middlewares);

  const enhancers = [middlewareEnhancer, monitorReducersEnhancer];
  const composedEnhancers = compose(...enhancers);
  const rootReducer = combineReducers(reducers);

  const store = createStore(rootReducer, preloadedState, composedEnhancers);

  return store;
}
