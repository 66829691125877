import { GET, PUT, POST, DELETE } from './constant-type';

export const REQUEST = {
  LOGIN: `REQUEST_LOGIN`,

  PAYMENT: `REQUEST_${GET.PAYMENT}`,
  PAYMENT_ID: `REQUEST_${GET.PAYMENT_ID}`,

  APARTMENTS: `REQUEST_${GET.APARTMENTS}`,
  APARTMENTS_POST: `REQUEST_${POST.APARTMENTS}`,
  APARTMENTS_ID: `REQUEST_${GET.APARTMENTS_ID}`,
  APARTMENTS_DELETE: `REQUEST_${DELETE.APARTMENTS}`,
  APARTMENTS_PUT: `REQUEST_${PUT.APARTMENTS}`,

  PROFILE: `REQUEST_GET_PROFILE`,
  PROFILE_PUT: `REQUEST_PUT_PROFILE`,

  FLOORS: `REQUEST_GET_FLOORS`,

  START: `REQUEST_START`,
  END: `REQUEST_END`,

  USER: `REQUEST_USER`,
  USER_MANAGEMENT: `REQUEST_GET_USER_MANAGEMENT`,
  USER_MANAGEMENT_POST: `REQUEST_POST_USER_MANAGEMENT`,
  USER_MANAGEMENT_ID: `REQUEST_${GET.USER_MANAGEMENT_ID}`,
  USER_MANAGEMENT_PUT: `REQUEST_${PUT.USER_MANAGEMENT}`,
  USER_MANAGEMENT_DELETE: `REQUEST_${DELETE.USER_MANAGEMENT}`,
  USER_PASSWORD_PUT: `REQUEST_${PUT.USER_PASSWORD}`,
};

export const RECEIVE = {
  LOGIN: `RECEIVE_LOGIN`,

  PAYMENT: `RECEIVE_${GET.PAYMENT}`,
  PAYMENT_ID: `RECEIVE_${GET.PAYMENT_ID}`,

  APARTMENTS: `RECEIVE_${GET.APARTMENTS}`,
  APARTMENTS_POST: `RECEIVE_${POST.APARTMENTS}`,
  APARTMENTS_ID: `RECEIVE_${GET.APARTMENTS_ID}`,
  APARTMENTS_DELETE: `RECEIVE_${DELETE.APARTMENTS}`,
  APARTMENTS_PUT: `RECEIVE_${PUT.APARTMENTS}`,

  FLOORS: `RECEIVE_GET_FLOORS`,

  PROFILE: `RECEIVE_${GET.PROFILE}`,
  PROFILE_PUT: `RECEIVE_${PUT.PROFILE}`,

  UNIT: `RECEIVE_GET_UNIT`,
  UNIT_ID: `RECEIVE_${GET.UNIT_ID}`,
  UNIT_POST: `RECEIVE_${POST.UNIT_POST}`,

  TOWER: `RECEIVE_${GET.TOWER}`,
  TOWER_ID: `RECEIVE_${GET.TOWER_ID}`,
  TOWER_POST: `RECEIVE_${POST.TOWER}`,
  TOWER_PUT: `RECEIVE_${PUT.TOWER}`,
  TOWER_DELETE: `RECEIVE_${DELETE.TOWER}`,

  USER: `RECEIVE_USER`,
  USER_MANAGEMENT: `RECEIVE_GET_USER_MANAGEMENT`,
  USER_MANAGEMENT_POST: `RECEIVE_${POST.USER_MANAGEMENT}`,
  USER_MANAGEMENT_ID: `RECEIVE_${GET.USER_MANAGEMENT_ID}`,
  USER_MANAGEMENT_PUT: `RECEIVE_${PUT.USER_MANAGEMENT}`,
  USER_MANAGEMENT_DELETE: `RECEIVE_${DELETE.USER_MANAGEMENT}`,
  USER_PASSWORD_PUT: `RECEIVE_${PUT.USER_PASSWORD}`,
};
