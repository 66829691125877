import {
  IMPORT_ASYNC_BULK_INVOICE,
  CLEAR_IMPORT,
  IMPORT_ASYNC_NOTIF_ERROR,
  RELOAD_IMPORT_ASYNC_BULK_INVOICE,
} from '../../actions/action-import-export/constant-type';

const initialState = {
  isUpload: false,
  bulk_invoice: null,
};

const importer = (state = initialState, { type, payload }) => {
  let data;
  switch (type) {
    case IMPORT_ASYNC_BULK_INVOICE:
      return {
        ...state,
        isUpload: true,
        bulk_invoice: data
          ? {
              ...state.bulk_invoice,
              ...payload,
            }
          : null,
      };

    case RELOAD_IMPORT_ASYNC_BULK_INVOICE:
      data = JSON.parse(localStorage.getItem(localStorage.getItem('ID_Apartment')));
      return {
        ...state,
        isUpload: data && data.isUpload ? true : false,
        bulk_invoice: data
          ? {
              ...state.bulk_invoice,
              ...data,
            }
          : null,
      };
    case IMPORT_ASYNC_NOTIF_ERROR:
      return {
        ...state,
        isUpload: false,
        bulk_invoice: {
          ...payload,
        },
      };

    case CLEAR_IMPORT:
      return {
        ...state,
        isUpload: false,
        bulk_invoice: null,
      };

    default:
      return state;
  }
};

export default importer;
