export const GET_NOTIFICATION_PAYMENT = 'GET_NOTIFICATION_PAYMENT';
export const SOCKET_ON_PAYMENT = 'SOCKET_ON_PAYMENT';
export const SOCKET_ON_LIST_PAYMENT = 'SOCKET_ON_LIST_PAYMENT';
export const UPDATE_IS_READ_PAYMENT = 'UPDATE_IS_READ_PAYMENT';

export const REQUEST = {
  GET_NOTIFICATION_PAYMENT: `REQUEST_${GET_NOTIFICATION_PAYMENT}`,
  SOCKET_ON_PAYMENT: `REQUEST_${SOCKET_ON_PAYMENT}`,
  SOCKET_ON_LIST_PAYMENT: `REQUEST_${SOCKET_ON_LIST_PAYMENT}`,
  UPDATE_IS_READ_PAYMENT: `REQUEST_${UPDATE_IS_READ_PAYMENT}`
};

export const RECEIVE = {
  GET_NOTIFICATION_PAYMENT: `RECEIVE_${GET_NOTIFICATION_PAYMENT}`,
  SOCKET_ON_PAYMENT: `RECEIVE_${SOCKET_ON_PAYMENT}`,
  SOCKET_ON_LIST_PAYMENT: `RECEIVE_${SOCKET_ON_LIST_PAYMENT}`,
  UPDATE_IS_READ_PAYMENT: `RECEIVE_${UPDATE_IS_READ_PAYMENT}`
};

export const FAILURE = {
  GET_NOTIFICATION_PAYMENT: `FAILURE_${GET_NOTIFICATION_PAYMENT}`,
  SOCKET_ON_PAYMENT: `FAILURE_${SOCKET_ON_PAYMENT}`,
  SOCKET_ON_LIST_PAYMENT: `FAILURE_${SOCKET_ON_LIST_PAYMENT}`,
  UPDATE_IS_READ_PAYMENT: `FAILURE_${UPDATE_IS_READ_PAYMENT}`
};
