import { GET,POST,DELETE,PUT } from './constant-type';
import { ERROR } from './constant-type-error';
export const SET = {
    CAR_BRANDS_GET:`SET_${GET.CAR_BRANDS}`,
    CAR_BRANDS_POST:`SET_${POST.CAR_BRANDS}`,
    CAR_BRANDS_PUT:`SET_${PUT.CAR_BRANDS}`,
    CAR_BRANDS_DELETE:`SET_${DELETE.CAR_BRANDS}`,
    CAR_BRANDS_PUT_ERROR:`SET_${ERROR.CAR_BRANDS_POST}`,

    PROVIDER:`SET_${GET.PROVIDER}`,
    PROVIDER_POST:`SET_${POST.PROVIDER}`,
    PROVIDER_DELETE:`SET_${DELETE.PROVIDER}`,
    PROVIDER_PUT:`SET_${PUT.PROVIDER}`,

    TOWER:`SET_${GET.TOWER}`,
    TOWER_POST:`SET_${POST.TOWER}`,
    TOWER_DELETE:`SET_${DELETE.TOWER}`,
    TOWER_PUT:`SET_${PUT.TOWER}`,

    ERROR_REQUEST:`SET_${ERROR.REQUEST}`,
    ERROR_RESPONSE:`SET_${ERROR.RESPONSE}`
    
}