import { REQUEST, RECEIVE } from "../../constant-type/constant-type-reports";

const initialState = {
  loading: false,
  code: null,
  payload: [],
  perpage: 0,
  count: 0,
  message: ""
};

const reportsGet = (
  state = initialState,
  { type, code, payload, perpage, count, message }
) => {
  switch (type) {
    case REQUEST.GET_REPORTS:
      return { ...state, loading: true };

    case RECEIVE.GET_REPORTS:
      return {
        ...state,
        loading: false,
        code,
        perpage,
        count,
        payload,
        message
      };

    default:
      return state;
  }
};

export default reportsGet;
