import axios from 'axios';
import * as jwt_decode from 'jwt-decode';
import jwt from 'jsonwebtoken';
import { REQUEST, RECEIVE, FAILURE } from '../../constant-type/constant-type-auth';

export const hideNotification = () => {
  return { type: 'ERROR_DEFAULT' };
};

const handleNotification = () => {
  return dispatch => {
    setTimeout(() => {
      dispatch(hideNotification());
    }, 3000);
  };
};

const checkRole = (token, role) => {
  return token.role.filter(value => role.includes(value));
};

const storage = ['TOKEN_MANAGEMENT', 'ID_Apartment', 'PATH_Apartment'];

export function logIn(account) {
  return async dispatch => {
    dispatch({ type: REQUEST.AUTH_LOGIN });
    try {
      var res = await axios.post(`/auth`, {}, { auth: account });
      const token = res.headers.authorization.split(' ');
      const data = jwt_decode(token[1]);
      const role = [
        'management',
        'finance',
        'checker',
        'security',
        'housekeeping',
        'talent',
      ];

      // Validasi apakah mempunyai role management
      if (checkRole(data, role).length === 0) {
        const error = {
          response: {
            code: 400,
            data: {
              message: 'Maaf Anda tidak memiliki akses kedalam sistem ini',
            },
          },
        };
        throw error;
      }

      setAuthorizationToken(token[1]);
      dispatch({ type: RECEIVE.AUTH_LOGIN, payload: data });
      return res.data;
    } catch (error) {
      if (error.response) {
        dispatch({ type: FAILURE.AUTH_LOGIN, payload: error.response.data });
        dispatch(handleNotification());
        return error.response.data;
      } else if (error.request) {
        let data = {
          code: 402,
          message:
            'Maaf terjadi kesalahan pada permintaan Anda, silakan coba beberapa saat lagi',
        };
        dispatch({ type: FAILURE.AUTH_LOGIN, payload: data });
        dispatch(handleNotification());
        return data;
      }
    }
  };
}

export function initAuthorizationToken(dispatch) {
  const token = localStorage.getItem('TOKEN_MANAGEMENT') || null;
  if (token !== null) {
    var decoded = jwt.decode(token, { complete: true });
    var date = new Date();
    if (token && decoded) {
      if (decoded.payload.exp < date.getTime()) {
        setAuthorizationToken(token);
        dispatch({ type: RECEIVE.AUTH_LOGIN, payload: decoded.payload });
      } else {
        unsetAuthorizationToken();
      }
    } else {
      unsetAuthorizationToken();
    }
  } else {
    unsetAuthorizationToken();
  }
}

export function setAuthorizationToken(token) {
  const storedToken = localStorage.getItem('TOKEN_MANAGEMENT') || null;
  if (storedToken === null || (storedToken !== null && storedToken !== token)) {
    localStorage.setItem('TOKEN_MANAGEMENT', token);
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  }
}

export function unsetAuthorizationToken() {
  delete axios.defaults.headers.common.Authorization;
  storage.map(data => {
    return localStorage.removeItem(data);
  });
}

export function logout() {
  return dispatch => {
    unsetAuthorizationToken();
    dispatch({ type: RECEIVE.AUTH_LOGOUT });
  };
}
