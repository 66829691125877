const ENV_NOW = process.env.NODE_ENV === 'production' ? 'production' : 'demo';

const configURL = {
  api: {
    production: 'https://api.manyoption.co.id',
    staging: 'https://staging-api.manyoption.co.id',
    demo: 'https://demo-api.manyoption.co.id',
    ulil: 'https://192.168.10.98:5000',
    nstdev: 'http://192.168.10.200:5000',
    docker: 'https://docker-api.nusatek.id',
  },
  socket: {
    production: 'https://socket.manyoption.co.id',
    staging: 'https://staging-socket.manyoption.co.id',
    demo: 'https://demo-socket2.manyoption.co.id',
    ulil: 'http://192.168.10.98:7002',
    nstdev: 'http://192.168.10.200:5050',
  },
  propertek: {
    // production: 'https://api2.propertek.id',
    production: 'https://api.manyoption.co.id',
    // staging: 'https://moapi.propertek.id',
    demo: 'https://demo-api.manyoption.co.id',
  },
};

export const ENV = () => {
  return {
    api: window._env_ ? window._env_.REACT_APP_API_URL : configURL.api[ENV_NOW],
    propertek: window._env_
      ? window._env_.REACT_APP_API_URL
      : configURL.propertek[ENV_NOW],
    socket: window._env_
      ? window._env_.REACT_APP_SOCKET_URL
      : configURL.socket[ENV_NOW],
  };
};
