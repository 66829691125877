import { REQUEST, RECEIVE } from '../../constant-type/constant-type-payment';

const initialState = {
  loading: false,
  code: null,
  payload: {
    items: []
  },
  message: ''
};

const paymentGet = (state = initialState, { type, code, payload, message }) => {
  switch (type) {
    case REQUEST.GET_PAYMENT_ID:
      return { ...state, loading: true };

    case RECEIVE.GET_PAYMENT_ID:
      return {
        ...state,
        loading: false,
        code,
        payload,
        message
      };

    default:
      return state;
  }
};

export default paymentGet;
