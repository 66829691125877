import { ERROR } from "../constant-type/constant-type-error";
import { SET } from "../constant-type/constant-type-set";

const InitialState = {
  request: false,
  badSetId: false
};

const menu = (state = InitialState, { type, payload, code }) => {
  switch (type) {
    case ERROR.REQUEST:
      return Object.assign({}, state, { request: true });

    case ERROR.BAD_SET_ID:
      return Object.assign({}, state, { badSetId: true });

    case SET.ERROR_REQUEST:
      return Object.assign({}, state, { request: false });

    case SET.ERROR_RESPONSE:
      return Object.assign({}, state, { badSetId: false });

    default:
      return state;
  }
};

export default menu;
