import {
  REQUEST_EMG_HOTLINE,
  RECEIVE_EMG_HOTLINE,
  REQUEST_EMG_NEARBY,
  RECEIVE_EMG_NEARBY,
} from '../../actions/action-emergencies/constant-type';

const initialState = {
  hotline: {
    loading: false,
    count: 0,
    perpage: 0,
    payload: [],
  },
  nearbies: {
    loading: false,
    count: 0,
    perpage: 0,
    payload: [],
  },
};

export default function emergencies(state = initialState, action) {
  switch (action.type) {
    case REQUEST_EMG_HOTLINE:
      return { ...state, hotline: { ...state.hotline, loading: true } };

    case RECEIVE_EMG_HOTLINE:
      return {
        ...state,
        hotline: {
          loading: false,
          ...action.payload,
        },
      };

    case REQUEST_EMG_NEARBY:
      return { ...state, nearbies: { ...state.nearbies, loading: true } };

    case RECEIVE_EMG_NEARBY:
      return {
        ...state,
        nearbies: {
          loading: false,
          ...action.payload,
        },
      };

    default:
      return state;
  }
}
