import { routerReducer as routing } from 'react-router-redux';
import auth from './reducer-auth/reducer-auth';
import menu from './reducer-menu/reducer-menu';
import user from './reducer-user/reducer-user';
import profile from './reducer-profile/reducer-profile';
import {
  paymentGet,
  paymentGetId,
  paymentDelete,
  paymentPost,
  paymentPut,
  paymentGetResidenceUnitList,
} from './reducer-payments';

import error from './reducer-error';

import {
  reportsGet,
  reportsGetId,
  reportGraph,
  reportStatistik,
} from './reducer-reports/index';

import state_apartment from './reducer-apartment/reducer-apartment';
import chat from './reducer-chat/reducer-chat';
import notifPayment from './reducer-notification/payment-notification-reducer';
import notification from './reducer-notification/reducer-notification';
import payments from './reducer-payments/reducer-payment';
import emergency from './reducer-emergencies/reducer-emergency';
import complaint from './reducer-complaint/reducer-complaint';
import purchaseToken from './reducer-purchase-token/reducers-purchase-token';
import information from './reducer-information/reducer-information';
import nominalPrice from './reducer-nominal-price/reducer-nominal-price';
import payment_list from './reducer-payment-list/reducer-payment-list';
import payment_list_v2 from './reducer-payment-list/reducer-payment-list-v2';
import reducer_bad_request from './reducer-bad-request/reducer-bad-request';
import reducer_import from './reducer-import/reducer-import';
import reducer_residents from './reducer-residents/reducer-residents';
import reducer_emergencies from './reducer-emergencies/reducer-emergencies';

export default {
  chat,
  notification,
  payments,
  emergency,
  complaint,
  information,
  nominalPrice,
  state_apartment,
  reportsGet,
  reportsGetId,
  reportGraph,
  reportStatistik,
  notifPayment,
  auth,
  paymentGet,
  paymentGetId,
  paymentDelete,
  paymentPut,
  paymentPost,
  paymentGetResidenceUnitList,
  menu,
  profile,
  routing,
  user,
  error,
  purchaseToken,
  payment_list,
  payment_list_v2,
  log_system: reducer_bad_request,
  reducer_import,
  reducer_residents,
  reducer_emergencies,
};
