import App from "./App";
import "./polyfill";
import React from "react";
import ReactDOM from "react-dom";
import { initAuthorizationToken } from "./redux/actions/auth/action-auth";

import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import configureStore from "./redux/Store";
import { unregister } from "./registerServiceWorker";

import { createBrowserHistory } from "history";
import initAPIConfig from "./config/config-api";
import "./config/i18n";
import "antd/dist/antd.css";
unregister();

const store = configureStore();
const history = createBrowserHistory();

initAPIConfig(store.dispatch);
initAuthorizationToken(store.dispatch);

const rootElement = document.getElementById("root");

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <App />
    </Router>
  </Provider>,
  rootElement
);
