import { REQUEST, RECEIVE } from "../../constant-type/constant-type-reports";

const initialState = {
  loading: false,
  code: null,
  payload: [
    {
      totalTransaction: 0,
      apartmentName: "",
      towerName: "",
      status: "Paid",
      startDate: "2018-10-30T00:00:00.000Z",
      endDate: "2018-11-06T23:59:59.999Z",
      totalClaim: 0,
      totalEarning: 0
    },
    {
      totalTransaction: 0,
      apartmentName: "",
      towerName: "",
      status: "Paid",
      startDate: "2018-11-07T00:00:00.000Z",
      endDate: "2018-11-14T23:59:59.999Z",
      totalClaim: 0,
      totalEarning: 0
    },
    {
      totalTransaction: 0,
      apartmentName: "",
      towerName: "",
      status: "Paid",
      startDate: "2018-11-15T00:00:00.000Z",
      endDate: "2018-11-22T23:59:59.999Z",
      totalClaim: 0,
      totalEarning: 0
    }
  ],
  perpage: 0,
  count: 0,
  message: ""
};

const reportGraph = (
  state = initialState,
  { type, code, payload, perpage, count, message }
) => {
  switch (type) {
    case REQUEST.GET_REPORTS_GRAPH:
      return { ...state, loading: true };

    case RECEIVE.GET_REPORTS_GRAPH:
      return {
        ...state,
        loading: false,
        code,
        perpage,
        count,
        payload,
        message
      };

    default:
      return state;
  }
};

export default reportGraph;
