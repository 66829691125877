export const GET_REPORTS = "GET_REPORTS";
export const GET_REPORTS_ID = "GET_REPORTS_ID";
export const GET_REPORTS_STATISTIK = "GET_REPORTS_STATISTIK";
export const GET_REPORTS_GRAPH = "GET_REPORTS_GRAPH";

export const REQUEST = {
  GET_REPORTS: `REQUEST_${GET_REPORTS}`,
  GET_REPORTS_ID: `REQUEST_${GET_REPORTS_ID}`,
  GET_REPORTS_STATISTIK: `REQUEST_${GET_REPORTS_STATISTIK}`,
  GET_REPORTS_GRAPH: `REQUEST_${GET_REPORTS_GRAPH}`
};

export const RECEIVE = {
  GET_REPORTS: `RECEIVE_${GET_REPORTS}`,
  GET_REPORTS_ID: `RECEIVE_${GET_REPORTS_ID}`,
  GET_REPORTS_STATISTIK: `RECEIVE_${GET_REPORTS_STATISTIK}`,
  GET_REPORTS_GRAPH: `RECEIVE_${GET_REPORTS_GRAPH}`
};

export const FAILURE = {
  GET_REPORTS: `FAILURE_${GET_REPORTS}`,
  GET_REPORTS_ID: `FAILURE_${GET_REPORTS_ID}`,
  GET_REPORTS_STATISTIK: `FAILURE_${GET_REPORTS_STATISTIK}`,
  GET_REPORTS_GRAPH: `FAILURE_${GET_REPORTS_GRAPH}`
};
