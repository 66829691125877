import {
  RECEIVE_NEW_RESIDENTS,
  REQUEST_NEW_RESIDENTS,
  CLEAR_NEW_RESIDENTS,
} from '../../actions/action-residents/constant-type';

const initialState = {
  new_residents: {
    loading: true,
    code: null,
    message: '',
    perpage: 0,
    totalDataCompleteNotVerified: 0,
    count: 0,
    payload: [],
  },
};

const importer = (state = initialState, { type, payload }) => {
  switch (type) {
    case REQUEST_NEW_RESIDENTS:
      return {
        ...state,
        new_residents: {
          ...state.new_residents,
          loading: true,
        },
      };

    case RECEIVE_NEW_RESIDENTS:
      return {
        ...state,
        new_residents: {
          ...state.new_residents,
          ...payload,
          loading: false,
        },
      };

    case CLEAR_NEW_RESIDENTS:
      return {
        ...state,
        new_residents: {
          loading: true,
          code: null,
          message: '',
          perpage: 0,
          totalDataCompleteNotVerified: 0,
          count: 0,
          payload: [],
        },
      };

    default:
      return state;
  }
};

export default importer;
