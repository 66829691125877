export const GET_PAYMENT = 'GET_PAYMENT';
export const GET_PAYMENT_ID = 'GET_PAYMENT_ID';
export const POST_PAYMENT = 'POST_PAYMENT';
export const PUT_PAYMENT = 'PUT_PAYMENT';
export const DELETE_PAYMENT = 'DELETE_PAYMENT';
export const GET_PAYMENT_PDF = 'GET_PAYMENT_PDF';
export const GET_ALL_RESIDENCE_UNIT = 'GET_ALL_RESIDENCE_UNIT';

export const REQUEST = {
  GET_PAYMENT: `REQUEST_${GET_PAYMENT}`,
  GET_PAYMENT_ID: `REQUEST_${GET_PAYMENT_ID}`,
  GET_PAYMENT_PDF: `REQUEST_${GET_PAYMENT_PDF}`,
  DELETE_PAYMENT: `REQUEST_${DELETE_PAYMENT}`,
  POST_PAYMENT: `REQUEST_${POST_PAYMENT}`,
  PUT_PAYMENT: `REQUEST_${PUT_PAYMENT}`,
  GET_ALL_RESIDENCE_UNIT: `REQUEST_${GET_ALL_RESIDENCE_UNIT}`,
};

export const RECEIVE = {
  GET_PAYMENT: `RECEIVE_${GET_PAYMENT}`,
  GET_PAYMENT_ID: `RECEIVE_${GET_PAYMENT_ID}`,
  GET_PAYMENT_PDF: `RECEIVE_${GET_PAYMENT_PDF}`,
  DELETE_PAYMENT: `RECEIVE_${DELETE_PAYMENT}`,
  POST_PAYMENT: `RECEIVE_${POST_PAYMENT}`,
  PUT_PAYMENT: `RECEIVE_${PUT_PAYMENT}`,
  GET_ALL_RESIDENCE_UNIT: `RECEIVE_${GET_ALL_RESIDENCE_UNIT}`,
};

export const FAILURE = {
  GET_PAYMENT: `FAILURE_${GET_PAYMENT}`,
  GET_PAYMENT_ID: `FAILURE_${GET_PAYMENT_ID}`,
  GET_PAYMENT_PDF: `FAILURE_${GET_PAYMENT_PDF}`,
  DELETE_PAYMENT: `FAILURE_${DELETE_PAYMENT}`,
  POST_PAYMENT: `FAILURE_${POST_PAYMENT}`,
  PUT_PAYMENT: `FAILURE_${PUT_PAYMENT}`,
  GET_ALL_RESIDENCE_UNIT: `FAILURE_${GET_ALL_RESIDENCE_UNIT}`,
};
