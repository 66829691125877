import constant from '../../constant-type';

const initialState = {
  loading: true,
  count: 0,
  perpage: 0,
  payload: [],
  error: null,
};

export default function paymentList(state = initialState, action) {
  switch (action.type) {
    case `REQUEST_${constant.PAYMENT_LIST}`:
      return { ...state, loading: true };

    case `RECEIVED_${constant.PAYMENT_LIST}`:
      const { payload, count, perpage } = action.payload;
      return {
        ...state,
        payload,
        perpage,
        count,
        loading: false,
        error: null,
      };

    case `FAILURE_${constant.PAYMENT_LIST}`:
      return { ...state, error: action.payload, payload: [], loading: false };

    default:
      return state;
  }
}
