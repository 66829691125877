// import { AUTH } from '../constant-type/constant-type-auth';
// import { REQUEST } from '../constant-type/constant-type-req-rec';
import { REQUEST, RECEIVE, FAILURE } from '../../constant-type/constant-type-auth';

const InitialState = {
  loading: false,
  loggedIn: false,
  payload: {},
  errors: {
    error: false,
    payload: '',
  },
};

const auth = (state = InitialState, { type, payload }) => {
  switch (type) {
    case RECEIVE.AUTH_LOGIN:
      return Object.assign({}, state, {
        loggedIn: true,
        payload: payload,
        loading: false,
      });

    case FAILURE.AUTH_LOGIN:
      return Object.assign({}, state, {
        errors: {
          error: true,
          message: payload.message,
        },
        loading: false,
      });

    case RECEIVE.AUTH_LOGOUT:
      return Object.assign({}, state, { loggedIn: false });

    case REQUEST.AUTH_LOGIN:
      return Object.assign({}, state, {
        loading: true,
      });

    // case REQUEST.END:
    //   return Object.assign({}, state, { loading: false });

    default:
      return state;
  }
};

export default auth;
