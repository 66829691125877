import constant from '../../constant-type';

const initialState = {
  loading: true,
  count: 0,
  perpage: 0,
  payload: [],
  error: null,
};

export default function payment_list_v2(state = initialState, action) {
  switch (action.type) {
    case `REQUEST_V2_${constant.PAYMENT_LIST}`:
      return { ...state, loading: true };

    case `RECEIVED_V2_${constant.PAYMENT_LIST}`:
      return {
        ...state,
        payload: action.payload.data,
        count: action.payload.count,
        perpage: action.payload.limit,
        loading: false,
        error: null,
      };

    case `FAILURE_V2_${constant.PAYMENT_LIST}`:
      return { ...state, error: action.payload, payload: [], loading: false };

    default:
      return state;
  }
}
