import {
  REQUEST,
  RECEIVE,
  FAILURE
} from "../../constant-type/constant-type-payment";

const initialState = {
  loading: false,
  code: null,
  payload: [],
  message: ""
};

const paymentGetResidenceUnitList = (
  state = initialState,
  { type, code, payload, message }
) => {
  switch (type) {
    case REQUEST.GET_ALL_RESIDENCE_UNIT:
      return { ...state, loading: true };

    case RECEIVE.GET_ALL_RESIDENCE_UNIT:
      return {
        ...state,
        loading: false,
        code,
        payload,
        message
      };

    case FAILURE.GET_ALL_RESIDENCE_UNIT:
      return {
        ...state,
        loading: false,
        code,
        payload,
        message
      };

    default:
      return state;
  }
};

export default paymentGetResidenceUnitList;
