import { REQUEST, RECEIVE } from '../../constant-type/constant-type-user';
import {
  REQUEST_USER,
  RECEIVE_USER,
  RECEIVE_DETAIL_USER,
} from '../../actions/action-user/action-type';

const InitialState = {
  loading: false,
  code: null,
  payload: {
    name: '',
    role: [],
  },
  list: {
    loading: false,
    code: null,
    payload: [],
  },
  detail: {},
};

const user = (state = InitialState, { type, payload, code }) => {
  switch (type) {
    case REQUEST.USER:
      return Object.assign({}, state, { loading: true });
    case RECEIVE.USER:
      return Object.assign({}, state, { loading: false, payload, code });

    case REQUEST_USER:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
        },
      };
    case RECEIVE_USER:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          ...payload,
        },
      };

    case RECEIVE_DETAIL_USER:
      return {
        ...state,
        detail: payload,
      };
    default:
      return state;
  }
};

export default user;
