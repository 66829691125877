export const GET = {
  APARTMENTS: 'GET_APARTMENTS',
  APARTMENTS_ID: 'GET_APARTMENTS_ID',
  PROFILE: 'GET_PROFILE',
  PROVIDER: 'GET_PROVIDER',
  PROVIDER_ID: 'GET_PROVIDER_ID',
  PAYMENT: 'GET_PAYMENT',
  PAYMENT_ID: 'GET_PAYMENT_ID',
  USER_MANAGEMENT: 'GET_USER_MANAGEMENT',
  USER_MANAGEMENT_ID: 'GET_USER_MANAGEMENT_ID',
  MANAGEMENT_KOMPLAIN: 'GET_MANAGEMENT_KOMPLAIN',
  MANAGEMENT_KOMPLAIN_ID: 'GET_MANAGEMENT_KOMPLAIN_ID',
};

export const PUT = {
  APARTMENTS: 'PUT_APARTMENTS',
  PROFILE: 'PUT_PROFILE',
  PAYMENT: 'PUT_PAYMENT',
  USER_MANAGEMENT: 'PUT_USER_MANAGEMENT',
  USER_PASSWORD: 'PUT_USER_PASSWORD',
  MANAGEMENT_KOMPLAIN: 'PUT_MANAGEMENT_KOMPLAIN',
};

export const POST = {
  APARTMENTS: 'POST_APARTMENTS',
  USER_MANAGEMENT: 'POST_USER_MANAGEMENT',
  MANAGEMENT_KOMPLAIN: 'POST_MANAGEMENT_KOMPLAIN',
};

export const DELETE = {
  APARTMENTS: 'DELETE_APARTMENTS',
  PAYMENT: 'DELETE_PAYMENT',
  USER_MANAGEMENT: 'DELETE_USER_MANAGEMENT',
  MANAGEMENT_KOMPLAIN: 'DELETE_MANAGEMENT_KOMPLAIN',
};
