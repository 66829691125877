import {
  RECEIVE_BAD_REQUEST,
  CLEAR_BAD_REQUEST,
} from '../../actions/action-bad-request/constant-type';

const initialState = {
  status: false,
  message: '',
  url: '',
};

const menu = (state = initialState, { type, payload, url }) => {
  switch (type) {
    case RECEIVE_BAD_REQUEST:
      return {
        ...state,
        ...payload,
        status: true,
        url,
      };

    case CLEAR_BAD_REQUEST:
      return {
        ...state,
        status: false,
        url: '',
      };
    default:
      return state;
  }
};

export default menu;
