import constant from '../../constant-type';

const initialState = {
  report_payment_answer_loading: false,
  report_payment_answer: [],
  report_payment_loading: false,
  report_payment: {
    id: '',
    authorID: '',
    authorName: '',
    email: '',
    paymentID: '',
    apartmentID: '',
    isResidence: true,
    title: ' ',
    detail: '',
    isSolved: false,
    sentOn: 1566547091965,
    invoiceID: '',
    customer: {
      userName: '',
      phoneNumber: '',
      userStatus: '',
      apartmentName: '',
      towerName: '',
      unitName: '',
      floorName: '',
      apartmentID: '',
      towerID: '',
      floorID: '',
      unitID: '',
    },
  },
  report_payment_complain: {
    loading: false,
    code: null,
    perpage: 0,
    count: 0,
    payload: [],
  },
};

const payment = (state = initialState, action) => {
  switch (action.type) {
    case `REQUEST_${constant.REPORT_PAYMENT_COMPLAIN}`:
      return {
        ...state,
        report_payment_complain: { ...state.report_payment_complain, loading: true },
      };

    case `RECEIVED_${constant.REPORT_PAYMENT_COMPLAIN}`:
      return {
        ...state,
        report_payment_complain: {
          ...state.report_payment_complain,
          ...action.payload,
          loading: false,
        },
      };

    case `REQUEST_${constant.REPORT_PAYMENT_ANSWER}`:
      return { ...state, report_payment_answer_loading: true };

    case `RECEIVED_${constant.REPORT_PAYMENT_ANSWER}`:
      return {
        ...state,
        report_payment_answer_loading: false,
        report_payment_answer: action.payload.reverse(),
      };

    case `REQUEST_${constant.REPORT_PAYMENT}`:
      return { ...state, report_payment_loading: true };

    case `RECEIVED_${constant.REPORT_PAYMENT}`:
      return {
        ...state,
        report_payment_loading: false,
        report_payment: action.payload,
      };

    default:
      return state;
  }
};

export default payment;
