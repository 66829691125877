import React, { Component, lazy, Suspense } from 'react';
import { Route, Switch, HashRouter, withRouter, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import numeral from 'numeral';
import 'numeral/locales';
import moment from 'moment';
import 'moment/locale/id'; // without this line it didn't work

import 'bootstrap/dist/css/bootstrap.min.css';
import '@coreui/icons/css/coreui-icons.min.css';
import 'font-awesome/css/font-awesome.min.css';
import 'rc-pagination/assets/index.css';
import './assets/scss/style.css';
import './assets/scss/style-change.css';
import './assets/scss/style-skeleton-screen.css';
import './assets/scss/global.css';

import { Page404 } from './modules/Pages';
import Loading from './components/Loading/Loading';
import { ModalInformationSystem } from './components/modal-information-system';
// import { message } from 'antd';
numeral.locale('pt-br');
moment.locale('id');

const DefaultLayout = withRouter(lazy(() => import('./containers')));
const Login = withRouter(lazy(() => import('./modules/Login/Login')));
const Home = withRouter(lazy(() => import('./modules/Home')));
const ThirdParty = withRouter(lazy(() => import('./third-party')));

let role = 'talent';
function PrivateRoute({ component: Component, props }) {
  const data = useSelector(state => state.auth);
  return (
    <Route
      {...props}
      render={props =>
        data.loggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}

function PublicRoute() {
  const data = useSelector(state => state.auth);

  if (!data.loggedIn) {
    return <Redirect to="/login" />;
  } else {
    if (data.payload.role.includes(role)) {
      return <Redirect to="/third-party" />;
    } else {
      return <Redirect to="/home" />;
    }
  }
}

function AdminRoute() {
  const data = useSelector(state => state.auth);

  // useEffect(() => {
  //   // console.log('hallo');
  //   var OneSignal = window.OneSignal || [];
  //   OneSignal.push(function() {
  //     OneSignal.init({
  //       appId: 'cb48b455-0407-4f7e-b94d-f80def2a3b40',
  //       promptOptions: {
  //         /* Change bold title, limited to 30 characters */
  //         siteName: 'OneSignal Documentation',
  //         /* Subtitle, limited to 90 characters */
  //         actionMessage:
  //           "We'd like to show you notifications for the latest news and updates.",
  //         /* Example notification title */
  //         exampleNotificationTitle: 'Example notification',
  //         /* Example notification message */
  //         exampleNotificationMessage: 'This is an example notification',
  //         /* Text below example notification, limited to 50 characters */
  //         exampleNotificationCaption: 'You can unsubscribe anytime',
  //         /* Accept button text, limited to 15 characters */
  //         acceptButtonText: 'ALLOW',
  //         /* Cancel button text, limited to 15 characters */
  //         cancelButtonText: 'NO THANKS',
  //         autoAcceptTitle: 'Click Allow',
  //       },
  //     });

  //     OneSignal.showHttpPrompt();
  //   });
  //   return () => {};
  // }, []);

  if (data.payload.role.includes(role)) {
    return (
      <Switch>
        <Route path="/third-party" name="third-party" component={ThirdParty} />
        <Redirect to="/third-party" />
      </Switch>
    );
  }
  return (
    <Switch>
      <Route path="/home" name="Home" component={Home} />
      <Route path="/apartment/:action?/:id?" name="Home" component={Home} />
      <Route path="/" name="Home" component={DefaultLayout} />
    </Switch>
  );
}

class App extends Component {
  render() {
    return (
      <React.Fragment>
        <ModalInformationSystem />
        {/* <NotifSystem /> */}
        <Suspense fallback={<Loading icon />} timeout={5000}>
          <HashRouter>
            <Switch>
              <Route exact path="/" component={PublicRoute} />
              <Route path="/login" component={Login} />
              <PrivateRoute path="/" component={AdminRoute} />
              <Route path="*" component={Page404} />
            </Switch>
          </HashRouter>
        </Suspense>
      </React.Fragment>
    );
  }
}

export default App;
