import constant from '../../constant-type';

const initialState = {
  count: 0,
  perpage: 0,
  payload: [],
  loading: false,
};

export default function information(state = initialState, action) {
  switch (action.type) {
    case `REQUEST_${constant.INFORMATION}`:
      return { ...state, loading: true };

    case `RECEIVED_${constant.INFORMATION}`:
      const { payload, count, perpage } = action.payload;
      return { ...state, payload, count, perpage, loading: false };

    default:
      return state;
  }
}
