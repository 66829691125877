
export const REQUEST = {
  AUTH_LOGIN: `REQUEST_AUTH_LOGIN`
};

export const RECEIVE = {
  AUTH_LOGIN: `RECEIVE_AUTH_LOGIN`,
  AUTH_LOGOUT: 'RECEIVE_AUTH_LOGOUT'
};

export const FAILURE = {
  AUTH_LOGIN: `FAILURE_AUTH_LOGIN`
};
