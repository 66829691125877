import constant from '../../constant-type';
import {
  CONS_UNREAD_NOTIF,
  CONS_LIST_NOTIF,
} from '../../actions/notification/action-reducer-notifications';

const initialState = {
  chat: [],
  report_payment: [],
  payment_bills: [],
  emergency: [],
  complain: [],
  purchase_token: [],
  reservasi: [],
  count_reservasi: 0,
  id: '',
};

const notification = (state = initialState, action) => {
  switch (action.type) {
    case `PUSH_${constant.CHAT_NOTIF}`:
      return { ...state, chat: action.payload };

    case `PUSH_${constant.REPORT_PAYMENT}`:
      return { ...state, report_payment: action.payload };

    case `PUSH_${constant.SET_ID_NOTIF}`:
      return { ...state, id: action.payload };

    case `PUSH_${constant.BILL_FROM_CHECKER}`:
      return { ...state, payment_bills: action.payload };

    case `PUSH_${constant.EMERGENCY}`:
      return { ...state, emergency: action.payload };

    case `PUSH_${constant.COMPLAIN}`:
      return { ...state, complain: action.payload };

    case `PUSH_${constant.PURCHASE_TOKEN}`:
      return { ...state, purchase_token: action.payload };

    case `PUSH_${constant.RESERVASI}`:
      return { ...state, reservasi: action.payload };

    case `PUSH_COUNT_FACILITIES`:
      return { ...state, count_reservasi: action.payload };

    case CONS_UNREAD_NOTIF:
      return {
        ...state,
        count_reservasi: action.payload,
      };

    case CONS_LIST_NOTIF:
      return {
        ...state,
        reservasi: action.payload,
      };

    default:
      return state;
  }
};

export default notification;
