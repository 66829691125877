import React from 'react';
// import { Spinner } from 'reactstrap';
import Logo from '../../assets/propertek.png';
import './Loading.css';

export default function Loading(props) {
  console.log('icon is', props.icon);
  return <div className="section-loading">
      <div className={`container-loading`}>
        <div className="loading">
          {props.icon && <div className="loading-img">
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <img src={Logo} style={{ width: '150px', height: 'inherit' }} alt="loading" />
              </div>
            </div>}
          {/* <Spinner
            color="success"
            style={{
              width: props.icon ? '5rem' : '3rem',
              height: props.icon ? '5rem' : '3rem',
            }}
            className="spinner"
          /> */}
        </div>
      </div>
    </div>;
}
