// import constant from '../../constant-type';
import {
  REQUEST_CHAT,
  RECEIVED_CHAT,
  PUSH_CHAT,
  RECEIVED_CHAT_LIST,
  RECEIVED_CHAT_INFO,
  RECEIVED_CHAT_ID,
  REQUEST_CHAT_LIST,
} from '../../actions/chat/constant-type';

const initialState = {
  data: [],
  chat_list: [],
  chat_notif: [],
  chat_bm_to_customer: [],
  load_chat: false,
  load_chat_list: false,
  id_chat: '',
  user_info: {},
};

const reducerChat = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_CHAT:
      return { ...state, load_chat: true };

    case RECEIVED_CHAT:
      return { ...state, data: action.payload, load_chat: false };

    case RECEIVED_CHAT_ID:
      return { ...state, id_chat: action.payload };

    case PUSH_CHAT:
      return { ...state, data: [...state.data, action.payload] };

    case REQUEST_CHAT_LIST:
      return { ...state, load_chat_list: true, chat_list: [] };

    case RECEIVED_CHAT_LIST:
      return { ...state, chat_list: action.payload, load_chat_list: false };

    case RECEIVED_CHAT_INFO:
      return {
        ...state,
        user_info: action.payload,
      };

    default:
      return state;
  }
};

export default reducerChat;
