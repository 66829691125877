import axios from 'axios';
import { ENV } from './config';
import { logout } from '../redux/actions/auth/action-auth';

export default function (dispatch) {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL || ENV().api;

  axios.interceptors.request.use((config) => {
    var token = localStorage.getItem('TOKEN_MANAGEMENT');
    config.headers.common['Authorization'] = `Bearer ${token}`;
    dispatch({ type: 'REQUEST_START' });
    return config;
  });

  axios.interceptors.response.use(
    (config) => {
      if (config.data) {
        const { data } = config;
        if (data.code === 405) {
          dispatch(logout());
        }
      }
      return config;
    },
    (err) => {
      if (err.response) {
        if (err.response.data.code === 401) {
          dispatch(logout());
        } else {
          return Promise.reject(err);
        }
      }
      return Promise.reject(err);
    }
  );
}
