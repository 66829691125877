import {
  REQUEST_GET_MENU,
  RECEIVE_GET_MENU,
  ONCHANGE_MENU,
  ONCHANGE_SUB_MENU,
  CLEAR_REDUCER_MENU,
  RECEIVE_GET_MENU_ID,
  REQUEST_GET_MENU_GROUP,
  ONCHANGE_MENU_NAME,
} from '../../actions/action-menu/constant-menu';

const InitialState = {
  list: {
    loading: true,
    statusCode: null,
    message: '',
    payload: [],
  },
  group: {
    loading: true,
    role: '',
    menus: [],
  },
};

const menu = (state = InitialState, { type, payload, menuID, subMenuID, isChecked, value }) => {
  var findMenu;
  var menus;
  switch (type) {
    case REQUEST_GET_MENU:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
        },
      };

    case REQUEST_GET_MENU_GROUP:
      return {
        ...state,
        group: {
          ...state.list,
          loading: true,
        },
      };

    case RECEIVE_GET_MENU:
      menus = payload.payload.map(value => {
        findMenu =
          state.group.menus.length > 0 &&
          state.group.menus.find(function(element) {
            return element.id === value.id;
          });

        // console.log(findMenu.id === value.id);

        value.isChecked = state.group.menus.some(element => element.id === value.id);
        if (value.children) {
          value.children.map(sub => {
            sub.isChecked = findMenu ? findMenu.children.some(item => item.id === sub.id) : false;
            return sub;
          });
        }
        return value;
      });

      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          ...payload,
          payload: menus,
        },
      };

    // case RECEIVE_GET_MENU:
    // let menus;
    // if (
    //   process.env.NODE_ENV !== 'development' &&
    //   localStorage.getItem('PATH_Apartment') !== 'landmarkresidence' &&
    //   localStorage.getItem('PATH_Apartment') !== 'apartmenta'
    // ) {
    //   let url = ['/package-receipt', '/purchase-token', '/reservasi-facilities'];
    //   let suburl = ['/masterdata/facilities', '/masterdata/nominal-price'];

    //   menus = payload.payload.filter(data => {
    //     if (data.url === '/masterdata') {
    //       let child = data.children.filter(value => !suburl.includes(value.url));
    //       data['children'] = child;
    //       return data;
    //     } else if (!url.includes(data.url)) {
    //       return data;
    //     } else {
    //       return null;
    //     }
    //   });
    // } else {
    //   menus = payload.payload;
    // }

    // return {
    //   ...state,
    //   list: {
    //     ...state.list,
    //     loading: false,
    //     payload: menus,
    //   },
    // };

    case RECEIVE_GET_MENU_ID:
      menus = state.list.payload.map(value => {
        findMenu = payload.payload.find(function(element) {
          return element.id === value.id;
        });

        if (payload.payload.some(item => item.id === value.id)) {
          value.isChecked = true;
          if (value.children) {
            value.children.map(sub => {
              sub.isChecked =
                findMenu.children && findMenu.children.some(item => item.id === sub.id);
              return sub;
            });
          }
        }
        return value;
      });

      return {
        ...state,
        list: {
          ...state.list,
          payload: menus,
        },
        group: {
          ...state.group,
          loading: false,
          menus: payload.payload,
        },
      };
    case ONCHANGE_MENU:
      menus = [...state.list.payload].map(value => {
        if (value.id === menuID) {
          if (!isChecked) {
            value.isChecked = false;
            value.children &&
              value.children.map(sub => {
                sub.isChecked = false;
                return sub;
              });
          } else {
            value.isChecked = true;
            value.children &&
              value.children.map(sub => {
                sub.isChecked = true;
                return sub;
              });
          }
        }
        return value;
      });

      return {
        ...state,
        list: {
          ...state.list,
          ...payload,
          payload: menus,
        },
        group: {
          ...state.group,
          menus: [...state.list.payload].filter(data => data.isChecked === true),
        },
      };

    case ONCHANGE_SUB_MENU:
      menus = [...state.list.payload].map(value => {
        if (value.id === menuID) {
          value.isChecked = true;
          value.children.map(sub => {
            if (sub.id === subMenuID) {
              sub.isChecked = isChecked;
            }
            return sub;
          });
          value.isChecked =
            value.isChecked && value.children.filter(data => data.isChecked === true).length > 0;
        }
        return value;
      });

      return {
        ...state,
        list: {
          ...state.list,
          ...payload,
          payload: menus,
        },
        group: {
          ...state.group,
          menus: menus.filter(data => data.isChecked === true),
        },
      };

    case ONCHANGE_MENU_NAME:
      menus = [...state.list.payload].map(value => {
        if (value.id === menuID) {
          value.name = payload;
        }
        return value;
      });

      return {
        ...state,
        list: {
          ...state.list,
          ...payload,
          payload: menus,
        },
        group: {
          ...state.group,
          menus: [...state.list.payload].filter(data => data.isChecked === true),
        },
      };

    case CLEAR_REDUCER_MENU:
      menus = state.list.payload.map(value => {
        value.isChecked = false;
        if (value.children) {
          value.children.map(sub => {
            sub.isChecked = false;
            return sub;
          });
        }
        return value;
      });

      return {
        ...state,
        list: {
          loading: true,
          ...state.list,
          ...payload,
          payload: menus,
        },
        group: {
          loading: true,
          role: '',
          menus: [],
        },
      };

    default:
      return state;
  }
};

export default menu;
